import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import List, { ListItem, ListItemSecondaryAction, ListItemText } from 'material-ui/List';
import { IconButton } from 'material-ui';
import PhoneIcon from '@material-ui/icons/Phone';
import MessageIcon from '@material-ui/icons/Message';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
    },
    paper: {
        padding: theme.spacing.unit * 2,
    },
    title: {
        marginBottom: theme.spacing.unit
    },
});

class Post extends React.Component {
    render() {
        const { classes, sessionInfo } = this.props;
        return (
            <div className={classes.root}>
                <Paper>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Receptionen"
                            />
                            <ListItemSecondaryAction>
                                <a href="tel:70605208">
                                    <IconButton edge="end" aria-label="call">
                                        <PhoneIcon />
                                    </IconButton>
                                </a>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Paper>
                {sessionInfo.sjakData && sessionInfo.sjakData.sjakleder && <Paper style={{ marginTop: 20 }}>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Kapitelleder"
                                secondary={sessionInfo.sjakData.sjakleder.navn}
                            />
                            <ListItemSecondaryAction>
                                <a href={'tel:' + sessionInfo.sjakData.sjakleder.telefon}>
                                    <IconButton edge="end" aria-label="call">
                                        <MessageIcon />
                                    </IconButton></a>
                                <a href={'sms:' + sessionInfo.sjakData.sjakleder.telefon}>
                                    <IconButton edge="end" aria-label="call">
                                        <PhoneIcon />
                                    </IconButton></a>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Paper>}
            </div>
        );
    }
}

Post.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        sessionInfo: state.sessionInfo
    };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Post)));

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import Typography from 'material-ui/Typography';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Grid from 'material-ui/Grid';
import { withRouter } from 'react-router-dom';
import { Divider, CircularProgress, Chip, IconButton, Button } from 'material-ui';
import List, { ListItem, ListItemText, ListItemAvatar } from 'material-ui/List';
import { fetchPostmandskaber } from './actions/postmandskaber';
import PhoneIcon from '@material-ui/icons/Phone';
import MessageIcon from '@material-ui/icons/Message';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
        height: "100%"
    },
    paper: {
        padding: theme.spacing.unit * 2,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing.unit
    },
    button: {
        marginTop: theme.spacing.unit,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    chip: {
        color: 'white',
        backgroundColor: '#326295',
    },
    chipHide: {
        color: 'white',
        backgroundColor: 'white',
    },
    emptyList: {
        marginTop: theme.spacing.unit * 5,
    },
    leftIcon: {
      marginRight: theme.spacing.unit,
    },
    iconSmall: {
      fontSize: 20,
    },
});

class Dashboard extends React.Component {
    componentDidMount() {
        this.props.dispatch(fetchPostmandskaber());
    }

    render() {
        const { classes, postmandskaber } = this.props;
        return (
            <div className={classes.root}>
                {postmandskaber && <div>
                    {postmandskaber.state === "loading" && 
                        <Grid container direction="row"
                        justify="center"
                        alignItems="center"><CircularProgress className={classes.progress} /></Grid>
                    } 
                    {postmandskaber.state === "loaded" &&
                    <Grid container justify="center">
                        <Grid item xs={12} sm={12} md={9} lg={6}>
                            <Typography variant="title" className={classes.title}>
                                <Grid container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <span>Postmandskaber</span>
                                    {postmandskaber.postmandskaber && <a href={navigator.appVersion.indexOf("iPhone") !== -1 ? `sms:/open?addresses=${postmandskaber.postmandskaber.map(p => p.telefon).join(',')}` : `sms:${postmandskaber.postmandskaber.map(p => p.telefon).join(',')}`} style={{textDecoration: 'none'}}>
                                        <Button className={classes.button} size="small">
                                            <MessageIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                            <span style={{fontSize: 10}}>SMS til alle</span>
                                        </Button>
                                    </a>}
                                </Grid>
                            </Typography>
                            {postmandskaber.postmandskaber ? postmandskaber.postmandskaber.length > 0 ? 
                            <div>
                                <Paper>
                                    <List className={classes.list}>
                                        {postmandskaber.postmandskaber.sort((a,b) => a.navn.localeCompare(b.navn)).map((sjak, index) => (
                                            <div key={`item-${index}`}>
                                                {index > 0 && <Divider />}
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Chip label={sjak.post_betegnelse ? sjak.post_betegnelse : '____'} className={sjak.post_betegnelse ? classes.chip : classes.chipHide} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={sjak.navn}
                                                    />
                                                    <ListItemAvatar>
                                                        <a href={`sms:${sjak.telefon}`}>
                                                            <IconButton aria-label="Delete">
                                                                <MessageIcon />
                                                            </IconButton>
                                                        </a>
                                                    </ListItemAvatar>
                                                    <ListItemAvatar>
                                                        <a href={`tel:${sjak.telefon}`}>
                                                            <IconButton aria-label="Delete">
                                                                <PhoneIcon />
                                                            </IconButton>
                                                        </a>
                                                    </ListItemAvatar>
                                                </ListItem>                
                                            </div>))
                                        }
                                    </List>
                                </Paper>
                            </div>: <Typography variant="caption" align="center" className={classes.emptyList}>Der kunne ikke findes nogen postmandskaber</Typography> : null}
                        </Grid>
                    </Grid>}
                </div> }
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        token: state.token,
        postmandskaber: state.postmandskaber,
    };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Dashboard)));
import React, { Component } from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from 'material-ui/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppBar from './AppBar';
import Login from './Login';
import Patrulje from './Patrulje';
import History from './History';
import Queue from './Queue';
import Dashboard from './Dashboard';
import CustomBottomNavigation from './CustomBottomNavigation';
import CustomBottomNavigationSjakleder from './CustomBottomNavigationSjakleder';
import { green, red, grey } from 'material-ui/colors';
import { withStyles } from 'material-ui/styles';
import { connect } from 'react-redux';
import Postmandskaber from './Postmandskaber';
import Contacts from './Contacts';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#273f69',
        },
        secondary: {
            main: '#326295',
        },
        danger: {
            main: red[500],
        },
        success: {
            main: green[500],
        },
    },
    mixins: {
        toolbar: {
            minHeight: 56,
        },
    },
    overrides: {
        MuiBottomNavigationAction: {
            // Name of the styleSheet
            root: {
                color: grey[400],
            },
            selected: {
                color: grey[50],
            },
        },
    },
});

const styles = {
    root: {
        marginTop: theme.mixins.toolbar.minHeight,
        marginBottom: 56,
    },
};

class App extends Component {
    // TODO: Can i remove this
    state = {
        sessionInfo: {
            authType: null,
            telefonnummer: null,
            needActivation: true,
            patruljeData: { patruljenavn: null },
        },
        token: localStorage.getItem('token'),
    };

    // TODO: Can i remove this
    setAuth = (sessionInfo, token) => {
        localStorage.setItem('token', token);
        this.setState({ sessionInfo: sessionInfo, token: token });
    };

    render() {
        const { classes, activated, sessionInfo } = this.props;

        return (
            <Router>
                <MuiThemeProvider theme={theme}>
                    <AppBar />
                    {this.props.activated ? (
                        <div>
                            {activated && sessionInfo.sjakData && sessionInfo.sjakData.role === "postmandskab" && <div className={classes.root}>
                                <Switch>
                                    <Route exact path="/" component={Queue} />
                                    <Route exact path="/historik" component={History} />
                                    <Route exact path="/patrulje/:id" component={Patrulje} />
                                    <Route exact path="/kontakt" component={Contacts} />
                                </Switch>
                                <CustomBottomNavigation />
                            </div>}
                            {activated && sessionInfo.sjakData && sessionInfo.sjakData.role === "sjakleder" && <div className={classes.root}>
                                <Switch>
                                    <Route exact path="/" component={Dashboard} />
                                    <Route exact path="/postmandskaber" component={Postmandskaber} />
                                </Switch>
                                <CustomBottomNavigationSjakleder />
                            </div>}
                            {activated && <Switch>
                            </Switch>}
                        </div>
                    ) : (
                        <div className={classes.root}>
                            <Login loginDone={this.setAuth} />
                        </div>
                    )}
                </MuiThemeProvider>
            </Router>
        );
    }
}
function mapStateToProps(state) {
    return {
        activated: state.activated,
        sessionInfo: state.sessionInfo,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(App));

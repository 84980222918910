import { RECEIVE_QUEUE, REQUEST_QUEUE, ERROR_QUEUE } from '../actions/queue';
function reducer(state = null, action) {
    switch (action.type) {
        case REQUEST_QUEUE:
            return {state: "loading", error: null, queue: null};
        case RECEIVE_QUEUE:
            return {state: "loaded", error: null, queue: action.queue};
        case ERROR_QUEUE:
            return {state: "error", error: action.error, queue: null};
        default:
            return state;
    }
}

export default reducer;

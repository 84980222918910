import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Typography from 'material-ui/Typography';
import { connect } from 'react-redux';
import { fetchQueue } from './actions/queue';
import { fetchPoints } from './actions/points';
import { invalidateActivation } from './actions/activated';
import Menu, { MenuItem } from 'material-ui/Menu';
import { Chip, Divider } from 'material-ui';
import { grey } from 'material-ui/colors';
import { fetchFinalekapitel } from './actions/finalekapitel';
import { fetchPostmandskaber } from './actions/postmandskaber';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    chip: {
        color: 'white',
        backgroundColor: '#326295',
    },
    version: {
        marginLeft: theme.spacing.unit * 2,
        color: grey[400],
        fontSize: 12
    },
    postbeskrivelseLink: {
        color: 'white',
        textDecoration: 'none'
    }
});

class MenuAppBar extends React.Component {
    state = {
        anchorEl: null,
    };

    logout = () => {
        this.handleClose();
        this.props.dispatch(invalidateActivation());
    };

    clearCache = () => {
        if (this.props.sessionInfo.sjakData.role === "postmandskab") {
            this.props.dispatch(fetchQueue(true));
            this.props.dispatch(fetchPoints(true));
        }
        if (this.props.sessionInfo.sjakData.role === "sjakleder") {
            this.props.dispatch(fetchFinalekapitel(true));
            this.props.dispatch(fetchPostmandskaber(true));
        }
        this.handleClose();
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { classes, sessionInfo, activated } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className={classes.root}>
                <AppBar position="fixed">
                    <Toolbar>
                        <div>
                            <img alt="VM Logo" src="/new_logo.png" style={{ height: 40 }} />
                        </div>
                        <Typography variant="title" color="inherit" className={classes.flex} align="center">
                            {sessionInfo && sessionInfo.sjakData && sessionInfo.sjakData.role === "postmandskab" && <div>
                                {sessionInfo.post && sessionInfo.post.postbeskrivelse_url &&
                                    <a href={sessionInfo.post.postbeskrivelse_url}
                                        className={classes.postbeskrivelseLink} target="_blank" rel="noopener noreferrer">
                                        <div>Post {sessionInfo.post.betegnelse}</div>
                                        <div style={{ fontSize: '50%', lineHeight: '50%' }}>Se postbeskrivelse</div>
                                    </a>}
                                {!sessionInfo.post.postbeskrivelse_url && <div>
                                    Post {sessionInfo.post.betegnelse}
                                </div>}
                            </div>
                            }
                            {sessionInfo && sessionInfo.sjakData && <span>
                                {sessionInfo.sjakData.role === "sjakleder" && <span>
                                    Kapitel {sessionInfo.finalekapitel.kapitel_betegnelse}
                                </span>}
                            </span>}
                            {(!sessionInfo || !sessionInfo.sjakData) && <span>
                                VM Sjak
                            </span>}
                        </Typography>
                        {activated && (
                            <div>
                                <div
                                    aria-owns={open ? 'menu-appbar' : null}
                                    aria-haspopup="true"
                                    onClick={this.handleMenu}
                                    color="inherit"
                                >
                                    <Chip label={sessionInfo.sjakData ? sessionInfo.sjakData.navn.split(' ')[0] : ''} className={classes.chip} />
                                </div>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={this.handleClose}
                                >
                                    {sessionInfo.post && sessionInfo.post.postbeskrivelse_url && <div>
                                        <a href={sessionInfo.post.postbeskrivelse_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                            <MenuItem onClick={this.handleClose}>Patrulje postbeskrivelse</MenuItem>
                                        </a>
                                        <a href={sessionInfo.post.postbeskrivelse_url.replace('postbeskrivelser', 'sjak_postbeskrivelser')} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                            <MenuItem onClick={this.handleClose}>Sjak postbeskrivelse</MenuItem>
                                        </a>
                                        <Divider />
                                    </div>}
                                    {sessionInfo.sjakData.role === "sjakleder" && <a href="tel:70605208" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                        <MenuItem onClick={this.handleClose}>Kontakt reception</MenuItem>
                                    </a>}
                                    <a href="https://filer.mesterskabet.dk/website/static/SJAK-APP-Userguide-rev2021-1.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                        <MenuItem onClick={this.handleClose}>Sjak brugervejledning</MenuItem>
                                    </a>
                                    <MenuItem onClick={this.clearCache}>Clear cache</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={this.logout}>Log ud</MenuItem>
                                    <Typography className={classes.version}>v1.1.0</Typography>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

MenuAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        token: state.token,
        activated: state.activated,
        sessionInfo: state.sessionInfo,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(MenuAppBar));

import { RECEIVE_PATRULJE, REQUEST_PATRULJE, REMOVE_PATRULJE_FROM_QUEUE, SET_PATRULJE_COMPLETED, SET_PATRULJE_IN_PROGRESS, ERROR_SETTING_PATRULJE_IN_PROGRESS } from '../actions/patrulje';
function reducer(state = null, action) {
    switch (action.type) {
        case REQUEST_PATRULJE:
            return {state: "loading", error: null, patrulje: null};
        case RECEIVE_PATRULJE:
            return {state: "loaded", error: null, patrulje: action.patrulje};
        case REMOVE_PATRULJE_FROM_QUEUE:
            state.patrulje.queue_status = "removed_by_sjak";
            return {state: "loaded", error: null, patrulje: state.patrulje};
        case ERROR_SETTING_PATRULJE_IN_PROGRESS:
            state.patrulje.queue_status = "error";
            return {state: "loaded", error: null, patrulje: state.patrulje};
        case SET_PATRULJE_IN_PROGRESS:
            state.patrulje.queue_status = "in_progress";
            return {state: "loaded", error: null, patrulje: state.patrulje};
        case SET_PATRULJE_COMPLETED:
            state.patrulje.queue_status = "completed";
            return {state: "loaded", error: null, patrulje: state.patrulje};
        default:
            return state;
    }
}

export default reducer;

import { invalidateActivation } from './activated';
import { receiveSessionInfo } from './login';

export const REQUEST_POSTMANDSKABER = 'REQUEST_POSTMANDSKABER';
function requestPostmandskaber() {
    return {
        type: REQUEST_POSTMANDSKABER,
    };
}

export const RECEIVE_POSTMANDSKABER = 'RECEIVE_POSTMANDSKABER';
function receivePostmandskaber(postmandskaber) {
    return {
        type: RECEIVE_POSTMANDSKABER,
        postmandskaber: postmandskaber,
        receivedAt: Date.now(),
    };
}

export const ERROR_POSTMANDSKABER = 'ERROR_POSTMANDSKABER';
// function setPostmandskaberError(error) {
//     return {
//         type: ERROR_POSTMANDSKABER,
//         error: error,
//         receivedAt: Date.now(),
//     };
// }

export function fetchPostmandskaber(force = false) {
    return (dispatch, getState) => {
        // grab current state
        const state = getState();
        if((!state.postmandskaber || state.postmandskaber.state !== "loading") || force){
            dispatch(requestPostmandskaber());
            return fetch(`${process.env.REACT_APP_API_BASE_URL}/sjakapi/kapitel_postmandskaber?sjak_token=${state.token}&_=${new Date().getTime()}`)
                .then(res => {
                    return res.json();
                })
                .then(json => {
                    if (json.status === 'NOT_AUTHENTIFICATED' || json.status === 'NO_SJAK') {
                        dispatch(invalidateActivation());
                    } else {
                        dispatch(receivePostmandskaber(json.postmandskaber));
                        dispatch(receiveSessionInfo(json.sessionInfo));
                    }
                });
        }
    };
}
import { invalidateActivation } from './activated';
import { receiveSessionInfo } from './login';

export const REQUEST_POINTS = 'REQUEST_POINTS';
function requestPoints() {
    return {
        type: REQUEST_POINTS,
    };
}

export const RECEIVE_POINTS = 'RECEIVE_POINTS';
function receivePoints(points) {
    return {
        type: RECEIVE_POINTS,
        points: points,
        receivedAt: Date.now(),
    };
}

export const CHANGE_POINT = 'CHANGE_POINT';
function changePatruljePoint(patrulje_id, point) {
    return {
        type: CHANGE_POINT,
        patrulje_id: patrulje_id,
        point: point,
        receivedAt: Date.now(),
    };
}

export const ERROR_POINTS = 'ERROR_POINTS';
// function setPointsError(error) {
//     return {
//         type: ERROR_POINTS,
//         receivedAt: Date.now(),
//         error: error,
//     };
// }

export function fetchPoints(force = false) {
    return (dispatch, getState) => {
        // grab current state
        const state = getState();

        if((!state.points || state.points.state !== "loading") || force){
            dispatch(requestPoints());
            return fetch(`${process.env.REACT_APP_API_BASE_URL}/sjakapi/points_history?sjak_token=${state.token}&_=${new Date().getTime()}`)
                .then(res => {
                    return res.json();
                })
                .then(json => {
                    if (json.status === 'NOT_AUTHENTIFICATED' || json.status === 'NO_SJAK') {
                        dispatch(invalidateActivation());
                    } else {
                        dispatch(receivePoints(json.points));
                        dispatch(receiveSessionInfo(json.sessionInfo));
                    }
                });
        }
    };
}


export function changePoint(token, patrulje_id, point) {
    return (dispatch, getState) => {
        // grab current state
        const state = getState();

        if(!state.points || state.points.state !== "loading"){
            return fetch(`${process.env.REACT_APP_API_BASE_URL}/sjakapi/change_point?patrulje_id=${patrulje_id}&point=${point}&sjak_token=${token}&_=${new Date().getTime()}`,{
                method: 'POST'
            })
                .then(res => {
                    return res.json();
                })
                .then(json => {
                    if (json.status === 'NOT_AUTHENTIFICATED' || json.status === 'NO_SJAK') {
                        dispatch(invalidateActivation());
                    } else {
                        console.log(json);
                        dispatch(changePatruljePoint(patrulje_id, point));
                        dispatch(receiveSessionInfo(json.sessionInfo));
                    }
                });
        }
    };
}
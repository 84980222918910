import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import { Paper, CircularProgress, Typography, Grid, Button, TextField, Avatar } from 'material-ui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPatrulje, removeFromQueue, startPost, slutPost } from './actions/patrulje';
import Dialog, { DialogActions, DialogContent, DialogContentText, DialogTitle } from 'material-ui/Dialog';
import { confirmAlert } from 'react-confirm-alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import List, { ListItem, ListItemText, ListItemIcon } from 'material-ui/List';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
    },
    paper: {
        padding: theme.spacing.unit * 2,
    },
    button: {
        marginTop: theme.spacing.unit,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    chipPatruljer: {
        color: 'white',
        backgroundColor: '#326295',
    },
    seniorAvatar: {
      margin: theme.spacing.unit,
      color: '#fff',
      backgroundColor: '#FF733C',
    },
    vaebnerAvatar: {
        marginRight: theme.spacing.unit,
        color: '#fff',
        backgroundColor: '#4678C8',
    },
    textField: {
        marginTop: theme.spacing.unit
    },
    list: {
        padding: 0
    }
});

class Post extends React.Component {
    state = {
        done: false,
        point: '',
    };

    constructor() {
        super();
        this.handleChangePoint = this.handleChangePoint.bind(this);
        this.removeFromQueue = this.removeFromQueue.bind(this);
        this.startPost = this.startPost.bind(this);
        this.slutPost = this.slutPost.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    handleChangePoint(evt) {
        this.setState({
            point: evt.target.value
        });
        //this.props.dispatch(formSetPoint(evt.target.value));
    }

    confirmRemoveFromQueue = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Dialog
                    open
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Fjern fra køen</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Er du sikker på at du vil fjerne patruljen fra køen?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Nej
                    </Button>
                    <Button onClick={() => {this.removeFromQueue();onClose();}} color="primary" autoFocus>
                        Ja
                    </Button>
                    </DialogActions>
                </Dialog>
                );
            },
        });
    };

    removeFromQueue() {
        this.props.dispatch(removeFromQueue(this.props.match.params.id, this.props.token));
    }

    startPost() {
        this.props.dispatch(startPost(this.props.match.params.id, this.props.token));
    }

    slutPost = event => {
        event.preventDefault();
        this.props.dispatch(slutPost(this.state.point, this.props.match.params.id, this.props.token));
    }

    componentDidMount() {
        this.props.dispatch(fetchPatrulje(this.props.match.params.id, this.props.token));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.patrulje.patrulje) {
            if (nextProps.patrulje.patrulje.queue_status === "removed_by_sjak" || nextProps.patrulje.patrulje.queue_status === "completed") {
                this.props.history.push('/');
            }
        }
    }

    goBack() {
        this.props.history.push(`/`);
    }

    render() {
        const { done, point } = this.state;
        const { classes, patrulje, post } = this.props;
        return (
            <div className={classes.root}>
                {patrulje && <div>
                    {patrulje.state === "loading" && 
                        <Grid container direction="row"
                        justify="center"
                        alignItems="center"><CircularProgress className={classes.progress} /></Grid>
                    } 
                    {patrulje.state === "loaded" &&
                    <Grid container justify="center">
                        <Grid item xs={12} sm={12} md={9} lg={6}>
                            <Paper className={classes.paper}>
                                <Button variant="flat" size="small" onClick={this.goBack}>
                                    <ArrowBackIcon />
                                    Tilbage
                                </Button>
                                <List className={classes.list}>
                                    <ListItem  className={classes.list}>
                                        {patrulje.patrulje.patruljetype === "vaebner" && <ListItemIcon><Avatar className={classes.vaebnerAvatar}>V</Avatar></ListItemIcon>}
                                        {patrulje.patrulje.patruljetype === "senior" && <ListItemIcon><Avatar className={classes.seniorAvatar}>S</Avatar></ListItemIcon>}
                                        <ListItemText
                                            primary={`${patrulje.patrulje.kortidentifikation} - ${patrulje.patrulje.patruljenavn}`}
                                        />
                                    </ListItem>
                                </List>
                                <Grid container spacing={16}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Kreds"
                                            value={patrulje.patrulje.kreds}
                                            className={classes.textField}
                                            fullWidth
                                            multiline
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Deltagere"
                                            value={patrulje.patrulje.deltagere}
                                            className={classes.textField}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                
                                {!done && <Grid container spacing={16}>
                                    <Grid item xs={6}>
                                        <Button
                                            className={classes.button}
                                            type="submit"
                                            variant="raised"
                                            fullWidth={true}
                                            disabled={patrulje.patrulje.queue_status === "in_progress"}
                                            onClick={this.startPost}
                                        >
                                            Start afviklingen
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            className={classes.button}
                                            type="submit"
                                            variant="raised"
                                            fullWidth={true}
                                            disabled={patrulje.patrulje.queue_status !== "in_progress"}
                                            onClick={() => {this.setState({done: true})}}
                                        >
                                            Stop afviklingen
                                        </Button>
                                    </Grid>
                                    {patrulje.patrulje.queue_status === "error" && 
                                    <Grid item xs={12}>
                                        <Typography color="error">
                                            Kunne ikke tilføje patruljen til køen
                                        </Typography>
                                    </Grid>}
                                    <Grid item xs={6}>
                                        <Button
                                            className={classes.button}
                                            type="submit"
                                            variant="raised"
                                            fullWidth={true}
                                            disabled={patrulje.patrulje.queue_status !== "in_queue"}
                                            onClick={this.confirmRemoveFromQueue}
                                        >
                                            Fjern fra kø
                                        </Button>
                                    </Grid>
                                </Grid>}
                                {done && 
                                    <div>
                                        <Typography variant="title" align="left">
                                            Indberet point
                                        </Typography>
                                        <form className={classes.form} noValidate autoComplete="off" onSubmit={this.slutPost}>
                                            <TextField
                                                name="point"
                                                onChange={this.handleChangePoint}
                                                value={point}
                                                id="point"
                                                type="text"
                                                pattern="\d*"
                                                label="Point"
                                                fullWidth
                                                error={!(Number(point) >= 0 && Number(point) <= post.max_point)}
                                                helperText={
                                                    !(point >= 0 && point <= post.max_point) ? 'Der kan kun gives mellem 0 og ' + post.max_point : 'Antal point patruljen har optjent på posten'
                                                }
                                            />
                                            <Button
                                                className={classes.button}
                                                type="submit"
                                                variant="raised"
                                                fullWidth={true}
                                                disabled={!(point.length > 0 && Number(point) >= 0 && Number(point) <= post.max_point)}
                                            >
                                                Send point
                                            </Button>
                                        </form>
                                    </div>
                                }
                            </Paper>
                        </Grid>
                    </Grid>}
                </div> }
            </div>
        );
    }
}

Post.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        post: state.sessionInfo.post,
        token: state.token,
        points: state.points,
        patrulje: state.patrulje
    };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Post)));

import { invalidateActivation } from './activated';
import { receiveSessionInfo } from './login';

export const REQUEST_QUEUE = 'REQUEST_QUEUE';
function requestQueue() {
    return {
        type: REQUEST_QUEUE,
    };
}

export const RECEIVE_QUEUE = 'RECEIVE_QUEUE';
function receiveQueue(queue) {
    return {
        type: RECEIVE_QUEUE,
        queue: queue,
        receivedAt: Date.now(),
    };
}

export const ERROR_QUEUE = 'ERROR_QUEUE';
// function setQueueError(error) {
//     return {
//         type: ERROR_QUEUE,
//         error: error,
//         receivedAt: Date.now(),
//     };
// }

export function fetchQueue(force = false) {
    return (dispatch, getState) => {
        // grab current state
        const state = getState();
        if((!state.queue || state.queue.state !== "loading") || force ){
            dispatch(requestQueue());
            return fetch(`${process.env.REACT_APP_API_BASE_URL}/sjakapi/active_queue?sjak_token=${state.token}&_=${new Date().getTime()}`)
                .then(res => {
                    return res.json();
                })
                .then(json => {
                    if (json.status === 'NOT_AUTHENTIFICATED' || json.status === 'NO_SJAK') {
                        dispatch(invalidateActivation());
                    } else {
                        dispatch(receiveQueue(json.queue));
                        dispatch(receiveSessionInfo(json.sessionInfo));
                    }
                });
        }
    };
}
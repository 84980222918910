import { RECEIVE_FINALEKAPITEL, REQUEST_FINALEKAPITEL, ERROR_FINALEKAPITEL } from '../actions/finalekapitel';
function reducer(state = null, action) {
    switch (action.type) {
        case REQUEST_FINALEKAPITEL:
            return {state: "loading", error: null, finalekapitel: null};
        case RECEIVE_FINALEKAPITEL:
            return {state: "loaded", error: null, finalekapitel: action.finalekapitel};
        case ERROR_FINALEKAPITEL:
            return {state: "error", error: action.error, finalekapitel: null};
        default:
            return state;
    }
}

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Typography from 'material-ui/Typography';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Grid from 'material-ui/Grid';
import { withRouter } from 'react-router-dom';
import { Divider, CircularProgress, Chip, IconButton, Button } from 'material-ui';
import List, { ListItem, ListItemText, ListItemAvatar } from 'material-ui/List';
import { fetchFinalekapitel } from './actions/finalekapitel';
import { grey } from 'material-ui/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneIcon from '@material-ui/icons/Phone';
import MessageIcon from '@material-ui/icons/Message';
import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from 'material-ui';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
        height: "100%"
    },
    paper: {
        padding: theme.spacing.unit * 2,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing.unit
    },
    button: {
        marginTop: theme.spacing.unit,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    chipPatruljer: {
        color: 'white',
        backgroundColor: '#326295',
    },
    progress: {
      margin: theme.spacing.unit * 2,
    },
    seniorAvatar: {
      margin: 0,
      color: '#fff',
      backgroundColor: '#FF733C',
    },
    vaebnerAvatar: {
        margin: 0,
        color: '#fff',
        backgroundColor: '#4678C8',
    },
    emptyList: {
        marginTop: theme.spacing.unit * 5,
    },
    haeder: {
        fontSize: 9,
        color: grey[600]
    },
    headerRow: {
        paddingBottom: theme.spacing.unit / 2,
        paddingRight: 56,
        paddingLeft: 24,
    },
    chip: {
        color: 'white',
        backgroundColor: '#326295',
    },
    expansionDetails: {
        padding: 0
    },
    list: {
        width: '100%'
    }
});

class Dashboard extends React.Component {
    componentDidMount() {
        this.props.dispatch(fetchFinalekapitel());
    }

    render() {
        const { classes, finalekapitel } = this.props;
        return (
            <div className={classes.root}>
                {finalekapitel && <div>
                    {finalekapitel.state === "loading" && 
                        <Grid container direction="row"
                        justify="center"
                        alignItems="center"><CircularProgress className={classes.progress} /></Grid>
                    } 
                    {finalekapitel.state === "loaded" &&
                    <Grid container justify="center">
                        <Grid item xs={12} sm={12} md={9} lg={6}>
                            <Typography variant="title"  className={classes.title}>
                                <Grid container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center">
                                    <span>Oversigt</span>
                                </Grid>
                            </Typography>
                            {finalekapitel.finalekapitel ? finalekapitel.finalekapitel.length > 0 ? 
                            <div>
                                <Grid container className={classes.headerRow}>
                                    <Grid item xs={4}>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className={classes.haeder} align="center">Færdig</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className={classes.haeder} align="center">Kø</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className={classes.haeder} align="center">Igang</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className={classes.haeder} align="center">Rest</Typography>
                                    </Grid>
                                </Grid> 
                                {finalekapitel.finalekapitel.sort((a,b) => new Date(a.ankomst_at) - new Date(b.ankomst_at)).map((post, index) => (
                                    <ExpansionPanel className="informationExpansion" key={`item-${index}`}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Chip label={post.betegnelse} className={classes.chip}/>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography align="center">{post.completed_count}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography align="center">{post.in_queue_count}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography align="center">{post.in_progress_count}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography align="center">{post.patruljer_in_streng - (post.completed_count + post.in_queue_count + post.in_progress_count)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.expansionDetails}>
                                            <Grid container direction="column">
                                                <Grid container
                                                    direction="row"
                                                    justify="space-between"
                                                    alignItems="center"
                                                    style={{padding: '0px 8px 0px 16px'}}
                                                >
                                                    <Typography variant="button" style={{fontSize: 14, marginTop: 6}}>Kodeord: <b>{post.kodeord}</b></Typography>
                                                </Grid>
                                                {post.postbeskrivelse_url && <Grid container
                                                    style={{padding: '0px 8px'}}
                                                >
                                                    <Grid item xs={6}>
                                                        <a href={post.postbeskrivelse_url} target="_blank" style={{ textDecoration: 'none' }}>
                                                            <Button className={classes.button} size="small">
                                                                Postbeskrivelse
                                                            </Button>
                                                        </a>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <a href={post.postbeskrivelse_url.replace('postbeskrivelser', 'sjak_postbeskrivelser')} target="_blank" style={{ textDecoration: 'none' }}>
                                                            <Button className={classes.button} size="small">
                                                                Sjak postbeskrivelse
                                                            </Button>
                                                        </a>
                                                    </Grid>
                                                </Grid>}
                                                <Grid container
                                                    direction="row"
                                                    justify="space-between"
                                                    alignItems="center"
                                                    style={{padding: '0px 8px 0px 16px'}}
                                                >
                                                    <Typography variant="button" style={{fontSize: 10, marginTop: 6}}>Postmandskaber</Typography>
                                                    {post.postmandskaber && <a href={navigator.appVersion.indexOf("iPhone") !== -1 ? `sms:/open?addresses=${post.postmandskaber.map(p => p.telefon).join(',')}` : `sms:${post.postmandskaber.map(p => p.telefon).join(',')}`} style={{textDecoration: 'none'}}>
                                                        <Button className={classes.button} size="small">
                                                            <MessageIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                                            <span style={{fontSize: 10}}>SMS til alle</span>
                                                        </Button>
                                                    </a>}
                                                </Grid>
                                                {post.postmandskaber && <List className={classes.list}>
                                                    {post.postmandskaber.sort((a,b) => a.post_betegnelse - b.post_betegnelse).map((sjak, index) => (
                                                        <div key={`item-${index}`}>
                                                            <Divider />
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={sjak.navn}
                                                                />
                                                                <ListItemAvatar>
                                                                    <a href={`sms:${sjak.telefon}`}>
                                                                        <IconButton aria-label="Delete">
                                                                            <MessageIcon />
                                                                        </IconButton>
                                                                    </a>
                                                                </ListItemAvatar>
                                                                <ListItemAvatar>
                                                                    <a href={`tel:${sjak.telefon}`}>
                                                                        <IconButton aria-label="Delete">
                                                                            <PhoneIcon />
                                                                        </IconButton>
                                                                    </a>
                                                                </ListItemAvatar>
                                                            </ListItem>                
                                                        </div>))
                                                    }
                                                </List>}
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>))
                                }
                            </div>: <Typography variant="caption" align="center" className={classes.emptyList}>Der er ingen patruljer i kø</Typography> : null}
                        </Grid>
                    </Grid>}
                </div> }
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        token: state.token,
        finalekapitel: state.finalekapitel,
    };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Dashboard)));

import { invalidateActivation } from './activated';
import { receiveSessionInfo } from './login';

export const REQUEST_PATRULJE = 'REQUEST_PATRULJE';
function requestPatrulje() {
    return {
        type: REQUEST_PATRULJE,
    };
}

export const RECEIVE_PATRULJE = 'RECEIVE_PATRULJE';
function receivePatrulje(patrulje) {
    return {
        type: RECEIVE_PATRULJE,
        patrulje: patrulje,
        receivedAt: Date.now(),
    };
}

export const REMOVE_PATRULJE_FROM_QUEUE = 'REMOVE_PATRULJE_FROM_QUEUE';
function removePatruljeFromQueue() {
    return {
        type: REMOVE_PATRULJE_FROM_QUEUE,
    };
}

export const ERROR_SETTING_PATRULJE_IN_PROGRESS = 'ERROR_SETTING_PATRULJE_IN_PROGRESS';
function errorSettingPatruljeInProgress() {
    return {
        type: ERROR_SETTING_PATRULJE_IN_PROGRESS,
    };
}

export const SET_PATRULJE_IN_PROGRESS = 'SET_PATRULJE_IN_PROGRESS';
function setPatruljeInProgress() {
    return {
        type: SET_PATRULJE_IN_PROGRESS,
    };
}

export const SET_PATRULJE_COMPLETED = 'SET_PATRULJE_COMPLETED';
function setPatruljeCompleted() {
    return {
        type: SET_PATRULJE_COMPLETED,
    };  
}

export function fetchPatrulje(patrulje_id, token) {
    return dispatch => {
        dispatch(requestPatrulje());
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/sjakapi/queue_patrulje?patrulje_id=${patrulje_id}&sjak_token=${token}&_=${new Date().getTime()}`)
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NOT_AUTHENTIFICATED' || json.status === 'NO_SJAK') {
                    dispatch(invalidateActivation());
                } else {
                    dispatch(receivePatrulje(json.info));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export function removeFromQueue(patrulje_id, token) {
    return dispatch => {
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/sjakapi/remove_queue_patrulje?patrulje_id=${patrulje_id}&sjak_token=${token}&_=${new Date().getTime()}`,{
            method: 'POST'
        })
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NOT_AUTHENTIFICATED' || json.status === 'NO_SJAK') {
                    dispatch(invalidateActivation());
                } else {
                    dispatch(removePatruljeFromQueue());
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export function startPost(patrulje_id, token) {
    return dispatch => {
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/sjakapi/start_queue_patrulje?patrulje_id=${patrulje_id}&sjak_token=${token}&_=${new Date().getTime()}`,{
            method: 'POST'
        })
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NOT_AUTHENTIFICATED' || json.status === 'NO_SJAK') {
                    dispatch(invalidateActivation());
                } else if (json.status === 'POINT_CLOSED') {
                    dispatch(errorSettingPatruljeInProgress());
                } else {
                    dispatch(setPatruljeInProgress());
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export function slutPost(point, patrulje_id, token) {
    return dispatch => {
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/sjakapi/complete_queue_patrulje?point=${point}&patrulje_id=${patrulje_id}&sjak_token=${token}&_=${new Date().getTime()}`,{
            method: 'POST'
        })
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NOT_AUTHENTIFICATED' || json.status === 'NO_SJAK') {
                    dispatch(invalidateActivation());
                } else {
                    dispatch(setPatruljeCompleted());
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}
import { combineReducers } from 'redux';
import token from './token';
import sessionInfo from './sessionInfo';
import points from './points';
import activated from './activated';
import loginError from './loginError';
import queue from './queue';
import patrulje from './patrulje';
import finalekapitel from './finalekapitel';
import postmandskaber from './postmandskaber';

export default combineReducers({
    token,
    sessionInfo,
    points,
    activated,
    loginError,
    queue,
    patrulje,
    finalekapitel,
    postmandskaber,
});

import { RECEIVE_POSTMANDSKABER, REQUEST_POSTMANDSKABER, ERROR_POSTMANDSKABER } from '../actions/postmandskaber';
function reducer(state = null, action) {
    switch (action.type) {
        case REQUEST_POSTMANDSKABER:
            return {state: "loading", error: null, postmandskaber: null};
        case RECEIVE_POSTMANDSKABER:
            return {state: "loaded", error: null, postmandskaber: action.postmandskaber};
        case ERROR_POSTMANDSKABER:
            return {state: "error", error: action.error, postmandskaber: null};
        default:
            return state;
    }
}

export default reducer;

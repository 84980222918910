import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import Typography from 'material-ui/Typography';
import { connect } from 'react-redux';
import Grid from 'material-ui/Grid';
import { withRouter } from 'react-router-dom';
import { fetchQueue } from './actions/queue';
import { Divider, CircularProgress, Chip, Avatar } from 'material-ui';
import List, { ListItem, ListItemText, ListItemIcon } from 'material-ui/List';
import Progress from './components/Progress';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
        height: "100%"
    },
    paper: {
        padding: theme.spacing.unit * 2,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing.unit
    },
    button: {
        marginTop: theme.spacing.unit,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    chipPatruljer: {
        color: 'white',
        backgroundColor: '#326295',
    },
    progress: {
      margin: theme.spacing.unit * 2,
    },
    seniorAvatar: {
      margin: 0,
      color: '#fff',
      backgroundColor: '#FF733C',
    },
    vaebnerAvatar: {
        margin: 0,
        color: '#fff',
        backgroundColor: '#4678C8',
    },
    emptyList: {
        marginTop: theme.spacing.unit * 5,
    }
});

class Post extends React.Component {
    componentDidMount() {
        this.props.dispatch(fetchQueue());
    }

    goToPatrulje(id) {
        this.props.history.push(`/patrulje/${id}`);
    }

    render() {
        const { classes, sessionInfo } = this.props;
        return (
            <div>
                <Progress completed={sessionInfo.post.completed_count} progress={sessionInfo.post.in_queue_count + sessionInfo.post.in_progress_count} rest={sessionInfo.post.patruljer_in_streng - (sessionInfo.post.completed_count + sessionInfo.post.in_queue_count + sessionInfo.post.in_progress_count)} />
                <div className={classes.root}>
                    {this.props.queue && <div>
                        {this.props.queue.state === "loading" && 
                            <Grid container direction="row"
                            justify="center"
                            alignItems="center"><CircularProgress className={classes.progress} /></Grid>
                        } 
                        {this.props.queue.state === "loaded" &&
                        <Grid container justify="center">
                            <Grid item xs={12} sm={12} md={9} lg={6}>
                                <Typography variant="title"  className={classes.title}>
                                    <Grid container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center">
                                        <span>Patruljer i kø</span>
                                        <Chip
                                            label={`${this.props.queue.queue ? this.props.queue.queue.length : 0} patruljer`}
                                            className={classes.chipPatruljer}
                                            />
                                    </Grid>
                                </Typography>
                                {this.props.queue.queue ? this.props.queue.queue.length > 0 ? <Paper>
                                    <List className={classes.list}>
                                        {this.props.queue.queue.sort((a,b) => new Date(a.ankomst_at) - new Date(b.ankomst_at)).map((patrulje, index) => (
                                                <div key={`item-${index}`}>
                                                    {index > 0 && <Divider />}
                                                    <ListItem
                                                            onClick={() => {
                                                                this.goToPatrulje(patrulje.patrulje_id);
                                                            }}>
                                                            {patrulje.patruljetype === "vaebner" && <ListItemIcon><Avatar className={classes.vaebnerAvatar}>V</Avatar></ListItemIcon>}
                                                            {patrulje.patruljetype === "senior" && <ListItemIcon><Avatar className={classes.seniorAvatar}>S</Avatar></ListItemIcon>}
                                                            <ListItemText
                                                                primary={`${patrulje.kortidentifikation} - ${patrulje.patruljenavn}`}
                                                                secondary={<i>Ankommet kl. {prettyTime(patrulje.ankomst_at)}</i>}
                                                            />
                                                            {patrulje.queue_status === "in_progress" &&<Chip
                                                                label={`Er igang`}
                                                                className={classes.chip}
                                                            />}
                                                    </ListItem>
                                                </div>
                                            ))
                                            }
                                    </List>
                                </Paper>: <Typography variant="caption" align="center" className={classes.emptyList}>Der er ingen patruljer i kø</Typography> : null}
                            </Grid>
                        </Grid>}
                    </div> }
                </div>
            </div>
        );
    }
}

Post.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        token: state.token,
        queue: state.queue,
        errors: state.pointsError,
        sessionInfo: state.sessionInfo
    };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Post)));

function prettyTime(time) {
    var date = new Date(time);
    return date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute:'2-digit'
    });
}
import { invalidateActivation } from './activated';
import { receiveSessionInfo } from './login';

export const REQUEST_FINALEKAPITEL = 'REQUEST_FINALEKAPITEL';
function requestFinalekapitel() {
    return {
        type: REQUEST_FINALEKAPITEL,
    };
}

export const RECEIVE_FINALEKAPITEL = 'RECEIVE_FINALEKAPITEL';
function receiveFinalekapitel(finalekapitel) {
    return {
        type: RECEIVE_FINALEKAPITEL,
        finalekapitel: finalekapitel,
        receivedAt: Date.now(),
    };
}

export const ERROR_FINALEKAPITEL = 'ERROR_FINALEKAPITEL';
// function setFinalekapitelError(error) {
//     return {
//         type: ERROR_FINALEKAPITEL,
//         error: error,
//         receivedAt: Date.now(),
//     };
// }

export function fetchFinalekapitel(force = false) {
    return (dispatch, getState) => {
        // grab current state
        const state = getState();
        if((!state.finalekapitel || state.finalekapitel.state !== "loading") || force){
            dispatch(requestFinalekapitel());
            return fetch(`${process.env.REACT_APP_API_BASE_URL}/sjakapi/kapitel_post_counts?sjak_token=${state.token}&_=${new Date().getTime()}`)
                .then(res => {
                    return res.json();
                })
                .then(json => {
                    if (json.status === 'NOT_AUTHENTIFICATED' || json.status === 'NO_SJAK') {
                        dispatch(invalidateActivation());
                    } else {
                        dispatch(receiveFinalekapitel(json.poster));
                        dispatch(receiveSessionInfo(json.sessionInfo));
                    }
                });
        }
    };
}
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import Typography from 'material-ui/Typography';
import { connect } from 'react-redux';
import Grid from 'material-ui/Grid';
import { withRouter } from 'react-router-dom';
import { fetchPoints, changePoint } from './actions/points';
import { Divider, CircularProgress, Avatar, Chip, Button, TextField } from 'material-ui';
import List, { ListItem, ListItemText, ListItemIcon } from 'material-ui/List';
import Dialog, { DialogActions, DialogContent, DialogTitle } from 'material-ui/Dialog';
import Progress from './components/Progress';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
    },
    paper: {
        padding: theme.spacing.unit * 2,
    },
    button: {
        marginTop: theme.spacing.unit,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    chipPatruljer: {
        color: 'white',
        backgroundColor: '#326295',
    },
    title: {
        marginBottom: theme.spacing.unit
    },
    seniorAvatar: {
      margin: 0,
      color: '#fff',
      backgroundColor: '#FF733C',
    },
    vaebnerAvatar: {
        margin: 0,
        color: '#fff',
        backgroundColor: '#4678C8',
    },
    emptyList: {
        marginTop: theme.spacing.unit * 5,
    }
});

class Post extends React.Component {
    state = {
        point: null,
        openPatrulje: null
    };

    constructor() {
        super();
        this.handleChangePoint = this.handleChangePoint.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.sendPointChange = this.sendPointChange.bind(this);
    }

    handleChangePoint(evt) {
        this.setState({
            point: evt.target.value
        });
        //this.props.dispatch(formSetPoint(evt.target.value));
    }

    openDialog = (patrulje) => {
        this.setState({
            openPatrulje: patrulje,
            point: Number(patrulje.points).toFixed(0)
        })
    };

    componentDidMount() {
        this.props.dispatch(fetchPoints(this.props.token));
    }

    sendPointChange() {
        this.props.dispatch(changePoint(this.props.token, this.state.openPatrulje.patrulje_id, this.state.point));
    }

    handleMenu = (id) => {
        this.setState({ openMenu: id });
    };

    handleClose = () => {
        this.setState({ openPatrulje: null, point: null });
    };

    render() {
        const { classes, points, post } = this.props;
        const { openPatrulje } = this.state;
        return (
            <div>
                <Progress />
                <div className={classes.root}>
                    {openPatrulje && <Dialog
                        open
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Ændre point for patrulje {openPatrulje.kortidentifikation}</DialogTitle>
                            <DialogContent>
                                    <TextField
                                        name="point"
                                        onChange={this.handleChangePoint}
                                        value={this.state.point}
                                        id="point"
                                        type="text"
                                        pattern="\d*"
                                        label="Point"
                                        fullWidth
                                        error={!(Number(this.state.point) >= 0 && Number(this.state.point) <= post.max_point)}
                                        helperText={
                                            !(this.state.point >= 0 && this.state.point <= post.max_point) ? 'Der kan kun gives mellem 0 og ' + post.max_point : 'Antal point patruljen har optjent på posten'
                                        }
                                    />
                            </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Annullér
                            </Button>
                            <Button onClick={() => {this.sendPointChange();this.handleClose();}} color="primary" 
                                                    disabled={!(this.state.point.length > 0 && Number(this.state.point) >= 0 && Number(this.state.point) <= post.max_point)}>
                                Gem
                            </Button>
                        </DialogActions>
                    </Dialog>}
                    {points && <div>
                        {points.state === "loading" && 
                            <Grid container direction="row"
                            justify="center"
                            alignItems="center"><CircularProgress className={classes.progress} /></Grid>
                        } 
                        {points.state === "loaded" &&
                        <Grid container justify="center">
                            <Grid item xs={12} sm={12} md={9} lg={6}>
                                <Typography variant="title"  className={classes.title}>
                                    <Grid container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center">
                                        <span>Indberettede point i dag</span>
                                        <Chip
                                            label={`${points.points ? points.points.length : 0} patruljer`}
                                            className={classes.chipPatruljer}
                                            />
                                    </Grid>
                                </Typography>
                                    {points.points ? points.points.length > 0 ? <Paper>
                                    <List className={classes.list}>
                                        {points.points
                                            ? points.points.sort((a,b) => new Date(b.loesning_at) - new Date(a.loesning_at)).map((patrulje, index) => (
                                                <div key={`item-${index}`}>
                                                    {index > 0 && <Divider />}
                                                    <ListItem>
                                                        {patrulje.patruljetype === "vaebner" && <ListItemIcon><Avatar className={classes.vaebnerAvatar}>V</Avatar></ListItemIcon>}
                                                        {patrulje.patruljetype === "senior" && <ListItemIcon><Avatar className={classes.seniorAvatar}>S</Avatar></ListItemIcon>}
                                                        <ListItemText
                                                            primary={`${patrulje.kortidentifikation} - ${patrulje.patruljenavn}`}
                                                            secondary={<i>Færdig kl. {prettyTime(patrulje.loesning_at)}</i>}
                                                        />
                                                        <Chip
                                                            label={`${parseInt(patrulje.points, 10)} point`}
                                                            className={classes.chip}
                                                            onClick={() => {this.openDialog(patrulje)}}
                                                        />
                                                    </ListItem>
                                                </div>
                                            ))
                                            : null}
                                    </List>
                                    </Paper>: <Typography variant="caption" align="center" className={classes.emptyList}>Der er ikke givet nogen point i dag</Typography> : null}
                            </Grid>
                        </Grid>}
                    </div> }
                </div>
            </div>
        );
    }
}

Post.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        post: state.sessionInfo.post,
        token: state.token,
        points: state.points,
    };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Post)));

function prettyTime(time) {
    var date = new Date(time);
    return date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute:'2-digit'
    });
}
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import { Typography } from 'material-ui';
import { green, grey, yellow } from 'material-ui/colors';

const styles = theme => ({
    progress: {
        fontSize: 10,
        height: 18,
        minHeight: 18,
        display: 'flex'
    },
    progressItem: {
        padding: '2px 10px',
        fontSize: 10,
        color: '#ffffff',
        minWidth: 48
    }
});

class Progress extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      console.log(this.props);
    }
    
    render() {
        const { classes, completed, progress, rest } = this.props;

        return (
            <div>
                <div className={classes.progress}>
                    {completed > 0 && <Typography style={{flex: completed, backgroundColor: green[700]}} className={classes.progressItem} align="center">
                        {completed} patruljer
                    </Typography>}
                    {progress > 0 && <Typography style={{flex: progress, backgroundColor: yellow[700]}} className={classes.progressItem} align="center">
                        {progress} patruljer
                    </Typography>}
                    {rest > 0 && <Typography style={{flex: rest, backgroundColor: grey[500]}} className={classes.progressItem} align="center">
                        {rest} patruljer
                    </Typography>}
                </div>
            </div>
        );
    }
}

Progress.propTypes = {
    classes: PropTypes.object.isRequired,
    completed: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    rest: PropTypes.number.isRequired,
};

export default withStyles(styles)(Progress);

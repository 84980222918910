import { RECEIVE_POINTS, REQUEST_POINTS, ERROR_POINTS, CHANGE_POINT } from '../actions/points';
function reducer(state = null, action) {
    switch (action.type) {
        case REQUEST_POINTS:
            return {state: "loading", error: null, points: null};
        case RECEIVE_POINTS:
            return {state: "loaded", error: null, points: action.points};
        case CHANGE_POINT:
            return {state: state.state, error: null, points: state.points.map(p => {
                if(p.patrulje_id === action.patrulje_id) p.points = action.point;
                return p;
            })};
        case ERROR_POINTS:
            return {state: "error", error: action.error, points: null};
        default:
            return state;
    }
}

export default reducer;

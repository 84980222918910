import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import BottomNavigation, { BottomNavigationAction } from 'material-ui/BottomNavigation';
import PeopleIcon from '@material-ui/icons/People';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.secondary.main,
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 1000,
    },
});

class CustomBottomNavigation extends React.Component {
    state = {
        value: 0,
    };

    componentDidMount() {
        var value;
        var path = this.props.location.pathname.toString();
        if (path.includes('postmandskaber')) {
            value = 1;
        } else {
            value = 0;
        }
        this.setState({ value: value });
    }

    handleChange = (event, value) => {
        switch (value) {
            case 0:
                // this.props.dispatch(fetchQueue());
                this.props.history.push('/');
                break;
            case 1:
                // this.props.dispatch(fetchPoints());
                this.props.history.push('/postmandskaber');
                break;
            default:
                this.props.history.push('/');
                break;
        }
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        const { value } = this.state;

        return (
            <BottomNavigation value={value} onChange={this.handleChange} showLabels className={classes.root}>
                <BottomNavigationAction label="Oversigt" icon={<DashboardIcon />} />
                <BottomNavigationAction label="Postmandskaber" icon={<PeopleIcon />} />
            </BottomNavigation>
        );
    }
}

CustomBottomNavigation.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        token: state.token,
    };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(CustomBottomNavigation)));
